import { DataProcessingFieldName, endPointName, typeVariable } from "enums/flow";
import { filter, findIndex, has, includes, intersection, isEmpty, keys, last, map, find, size, mapKeys, isArray } from "lodash";
import { Edge, Node, getOutgoers } from "react-flow-renderer";
import * as types from "./flow-editor/tabPanelFlowEditor/NodeTypes";
import { userSpaceData } from "./type";
import { generateIdFlow } from "utils/helper";

export const handleCorrectNodes = (
  targetValidations: {
    [nodeId: string]: number;
  },
  orderedNodesRelatedFlow: { [nodeId: string]: { index: number; parentId: string } } = {}
) => {
  let lastItem = last(keys(targetValidations)) || "";
  let infoLastItem = orderedNodesRelatedFlow?.[lastItem] || {};
  // console.log("infoLastItem",infoLastItem,orderedNodesRelatedFlow)
  // in this case the targetValidations has parentId with value zero, so should exit
  if (isEmpty(infoLastItem)) {
    return null;
  }
  Object.assign(targetValidations, {
    [infoLastItem?.parentId || ""]: infoLastItem?.index,
  });
  if (infoLastItem?.index === 0 && infoLastItem?.parentId === "0") {
    return null;
  }
  handleCorrectNodes(targetValidations, orderedNodesRelatedFlow);
};

export const handleVarFound = (
  targetNodeID: string,
  resOrderedNodes: any,
  //  {
  //   [flowId: string]: {
  //     [nodeId: string]: { index: number; parentId: string };
  //   };
  // } = {},
  nodesIdArr: string[],
  targetNodeName: string = "0"
) => {
  let idsNodes: string[] = keys(resOrderedNodes);
  let index: number = findIndex(idsNodes, (nd) => nd === targetNodeID);
  let targetIdsNodes: string[] = idsNodes.splice(0, index + 1).reverse();

  let commonElements = intersection(nodesIdArr, targetIdsNodes) || [];
  let isNotFound: boolean = true;
  if (commonElements.length > 0) {
    let correctNodes: { [nodeId: string]: number } = {
      [resOrderedNodes?.[targetNodeID]?.parentId]:
        resOrderedNodes?.[targetNodeID]?.index || 0,
    };
    handleCorrectNodes(correctNodes, resOrderedNodes);
    map(commonElements, (i) => {
      if (
        has(correctNodes, resOrderedNodes?.[i]?.parentId) &&
        correctNodes[resOrderedNodes?.[i]?.parentId] ===
        resOrderedNodes?.[i]?.index
      ) {
        isNotFound = false;
      }
    });
  } else {
    isNotFound = true;
  }
  return isNotFound;
};

// return array of nodes that had errors related to variables and after updating the variables,these errors became not correct  and must cancel it
export const getNodesHadeErrorsRelatedVars = (
  userSpaceVars: any,
  targetNodeId: string,
  orderedNodes: any = {},
  nodesConnectedWithSystemVars: any = {},
  errorsRelatedVariables: any = {},
  getVariables: any = () => { },
  newUserSpace: any = undefined

) => {
  let targetCorrectNodes: any = {};
  map(userSpaceVars, (userSpaceVar) => {
    let nodeConnectedWithVar: any = {};
    if (userSpaceVar?.type === typeVariable.ARRAY) {
      nodeConnectedWithVar = nodesConnectedWithSystemVars?.[userSpaceVar?.title]?.[
        userSpaceVar?.type || {}
      ]?.[JSON.stringify(userSpaceVar?.arrKeys)];
    } else {
      nodeConnectedWithVar =
        nodesConnectedWithSystemVars?.[userSpaceVar?.title]?.[
        userSpaceVar?.type
        ] || {}
    }
    //COMMIT: Disable this code that used with One group of flows(*1)
    // let basicNodes: any = [...keys(nodeConnectedWithVar) || "", targetNodeId]
    // basicNodes.sort((a: any, b: any) => {
    //   return keys(orderedNodes).indexOf(a) - keys(orderedNodes).indexOf(b);
    // });
    // const [_, arrayAfterSplitValue] = split(basicNodes, targetNodeId)
    // const filteredNodesConnectedWithVars: any = Object.fromEntries(
    //   Object.entries(nodeConnectedWithVar || {}).filter(([key]) => arrayAfterSplitValue.includes(key))
    // );
    //END(*1)
    map(keys(nodeConnectedWithVar), (nId) => {
      if (errorsRelatedVariables?.[nId]) {
        //COMMIT: enable this code that used with multi group of flows(*2)
        let resultVar = getVariables(true, nId, undefined,
          undefined,
          undefined,
          undefined,
          newUserSpace,
          false) || [];
        //TODO 11/15
        let isFound = resultVar.find(
          (v: any) => (
            (has(v, "relatedBy") && ([targetNodeId, nId].includes(v?.relatedBy))) || [targetNodeId, nId].includes(v?.id))
        );//node that has ndId as id, uses varaible called "item" 
        if (isFound) {
          targetCorrectNodes[nId] = nodeConnectedWithVar[nId];
        }
        //END(*2)
        //COMMIT: Disable this code that used with One group of flows(*3)
        // let correctNodes: { [nodeId: string]: number } = {
        //   [orderedNodes?.[nId]?.parentId]:
        //     orderedNodes?.[nId]?.index || 0,
        // };
        // handleCorrectNodes(correctNodes, orderedNodes);
        // if (
        //   has(correctNodes, orderedNodes?.[targetNodeId]?.parentId) &&
        //   correctNodes[orderedNodes?.[targetNodeId]?.parentId] ===
        //   orderedNodes?.[targetNodeId]?.index
        // ) {
        //   targetCorrectNodes[nId] = nodeConnectedWithVar[nId];
        // }
        //END(*3)
      }
    });
  });
  return targetCorrectNodes;
};

export const onExtractErrors = (nodesHadeErrorVar: any, newErrorsArr: any = {}) => {
  map(keys(nodesHadeErrorVar), (nId) => {
    newErrorsArr = {
      ...newErrorsArr,
      [nId]: {
        ...newErrorsArr?.[nId],
        rulesId: filter(
          newErrorsArr?.[nId]?.rulesId,
          (i) => !includes(nodesHadeErrorVar[nId], i)
        ),
      },
    };
    if (newErrorsArr?.[nId]?.rulesId?.length === 0) {
      delete newErrorsArr?.[nId];
    }
  });
  return newErrorsArr;
};

export const handleNextNode = (
  targetNode: Node,
  res: { [nodeId: string]: { index: number; parentId: string } },
  nds: Node[],
  eds: Edge[],
  index: number = 0,
  parentId: string = "0"
) => {
  try {
    let nextNodes = getOutgoers(targetNode, nds, eds);
    let isOneBranch = true;
    if (nextNodes?.length === 0) {
      return null;
    }
    if (
      nextNodes?.length > 1 ||
      (targetNode?.type === types.HTTP_REQUEST &&
        (targetNode?.data?.hasSuccessNode || false)) ||
      (targetNode?.type === types.WAIT_RESPONSE &&
        (targetNode?.data?.hasRepliedNode || false))
    ) {
      isOneBranch = false;
    }
    if (isOneBranch) {
      let obj: { index: number, parentId: string, subFlowNodesId?: any } = { index, parentId };
      let resOrderedSubFlowNodes: {
        [nodeId: string]: { index: number; parentId: string };
      } = {};
      // if(nextNodes[0].type===types.SUB_FLOW){

      //   let subFlowId=nextNodes[0]?.data?.subFlow?.id||""
      //   if(!isEmpty(subFlowId)){
      //     let startSubFlow:Node= find(nds,(n)=>n?.extent==="parent"  && n?.parentNode === nextNodes[0]?.data?.subFlow?.id) ||{} as Node
      //     handleNextNode(startSubFlow, resOrderedSubFlowNodes, nds, eds);
      //     if(!isEmpty(resOrderedSubFlowNodes)){
      //       map(resOrderedSubFlowNodes,(internalObj:any,externalKey:string)=>{
      //         resOrderedSubFlowNodes={
      //           ...resOrderedSubFlowNodes,
      //           [externalKey]:{
      //             ...resOrderedSubFlowNodes?.[externalKey],
      //             index:+resOrderedSubFlowNodes?.[externalKey]?.index+obj["index"],
      //             parentId: resOrderedSubFlowNodes?.[externalKey]?.parentId==="0"? obj["parentId"]:resOrderedSubFlowNodes?.[externalKey]?.parentId
      //           }
      //         }
      //       })
      //     }
      //   }
      //   obj={...obj,subFlowNodesId:{...resOrderedSubFlowNodes}}
      // }
      Object.assign(res, { [nextNodes[0]?.id]: { ...obj }, ...resOrderedSubFlowNodes });
      if (
        includes(
          [
            types.END_CONDITION,
            types.END_HTTP,
            types.END_WAIT_RES,
            types.ADD_STEP,
            types.END_FLOW,
          ],
          nextNodes[0]?.type
        ) ||
        (has(nextNodes[0]?.data, "isEndCondition") && //this validation for nodes that connect with the end of ifCondition node AS old flow
          nextNodes[0]?.data?.isEndCondition)
      ) {
        return null;
      }
      handleNextNode(nextNodes[0], res, nds, eds, index, parentId);
    } else {
      let resBath: { [nodeId: string]: { index: number; parentId: string } } =
        {};
      handleNextNodes(
        nextNodes,
        targetNode,
        resBath,
        nds,
        eds,
        res,
        index,
        parentId
      );
    }
  } catch (error) {
    console.error("error handleNextNode")
  }

};
export const handleNextNodes = (
  targetNodes: Node[],
  targetNode: Node,
  resBath: { [nodeId: string]: { index: number; parentId: string } },
  nds: Node[],
  eds: Edge[],
  res: { [nodeId: string]: { index: number; parentId: string } },
  prevIndex: number,
  parentId: string = "0"
) => {
  try {
    let obj: Object = { index: prevIndex, parentId: targetNode?.id };
    map([...targetNodes], (n, index) => {
      let currentIndex = prevIndex + (index + 1);
      Object.assign(resBath, { [n?.id]: { ...obj, index: currentIndex } });
      handleNextNode(n, resBath, nds, eds, currentIndex, targetNode?.id);
    });
    let nameEndPoint = endPointName.CONDITION;
    if (targetNode?.type === types.WAIT_RESPONSE) {
      nameEndPoint = endPointName.WAIT_RES;
    }
    if (targetNode?.type === types.HTTP_REQUEST) {
      nameEndPoint = endPointName.HTTP_REQUEST;
    }
    let n1: Node =
      find(nds, (n) => n?.id === targetNode?.data?.[nameEndPoint]) ||
      ({} as Node);
    if (!isEmpty(n1)) {
      Object.assign(resBath, { [n1?.id]: { ...obj, parentId } });
    }
    Object.assign(res, resBath);
    if (isEmpty(n1)) {
      return null;
    }
    handleNextNode(n1, res, nds, eds, prevIndex, parentId);
  } catch (error) {
    console.error("error in handleNextNodes")
  }

};


export const handleNextIfconditionNode = (
  idPointerIfConditionNode: string = "",
  newNodesForConditiond: { [idNode: string]: Node },
  targetNode: Node,
  isPreventPassingCond = false,
  resNodesBeforeEndpoint: { [ifConditionId: string]: string[] },
  eds: Edge[],
  nds: Node[],
  resIfConditionNodesConnectedTarget: {
    [ifConditionId: string]: string[];
    isBasic: any; //  indicates to the end of the parent ifCondition node
  },
  nodesAsObject: { [idNode: string]: Node },
  nameEndPoint = "endPointId"
) => {
  if (
    [types.IF_CONDITION, types.HTTP_REQUEST].includes(
      newNodesForConditiond[targetNode?.data?.[nameEndPoint]]?.type || ""
    )
  ) {
    // console.log("handleNextIfconditionNode",targetNode,idPointerIfConditionNode)
    handleNextIfconditionNode(
      idPointerIfConditionNode,
      newNodesForConditiond,
      newNodesForConditiond?.[targetNode?.data?.[nameEndPoint]],
      isPreventPassingCond,
      resNodesBeforeEndpoint,
      eds,
      nds,
      resIfConditionNodesConnectedTarget,
      nodesAsObject,
      nameEndPoint
    );
  } else {
    if (!isPreventPassingCond) {
      let resLastNode: any = {};
      handleLastNodeConnectedIfConditionNode(
        newNodesForConditiond,
        resLastNode,
        newNodesForConditiond[targetNode?.data?.[nameEndPoint]],
        eds,
        nds,
        nodesAsObject?.[idPointerIfConditionNode]?.data?.[nameEndPoint],
        nameEndPoint
      );
      let outgoersNodes: Node[] = getOutgoers(targetNode, nds, eds);
      isPreventPassingCond = true;
      handleNestedNodesUnderIfCondition(
        nodesAsObject[targetNode?.id],
        outgoersNodes,
        resNodesBeforeEndpoint,
        eds,
        nds,
        resIfConditionNodesConnectedTarget,
        true,
        targetNode?.id,
        isPreventPassingCond,
        newNodesForConditiond,
        nodesAsObject,
        resLastNode,
        nameEndPoint
      );
    }
  }
};
export const handleLastNodeConnectedIfConditionNode = (
  newNodesForConditiond: { [idNode: string]: Node },
  resLastNode: Node = {} as Node,
  targetNode: Node,
  eds: Edge[],
  nds: Node[],
  idPointerIfConditionNode: string,
  nameEndPoint: string = "endPointId"
) => {
  try {
    let outgoersNodes: Node[] = getOutgoers(targetNode || {}, nds, eds) || {};
    map([...outgoersNodes], (childNode) => {
      if (
        [types.IF_CONDITION, types.HTTP_REQUEST].includes(childNode?.type || "")
      ) {
        handleLastNodeConnectedIfConditionNode(
          newNodesForConditiond,
          resLastNode,
          newNodesForConditiond[childNode?.data?.[nameEndPoint]],
          eds,
          nds,
          idPointerIfConditionNode,
          nameEndPoint
        );
      } else {
        let targetEdges = filter([...eds], (e) => e?.source === childNode?.id);
        map([...targetEdges], (o) => {
          if (o?.target === idPointerIfConditionNode) {
            Object.assign(resLastNode, { ...childNode });
            return null;
          }
          handleLastNodeConnectedIfConditionNode(
            newNodesForConditiond,
            resLastNode,
            childNode,
            eds,
            nds,
            idPointerIfConditionNode,
            nameEndPoint
          );
        });
      }
    });

  } catch (error) {
    console.error(error)
  }

};

export const handleNestedNodesUnderIfCondition = (
  targetNode: Node,
  outgoersNodesTargetNode: Node[],
  resNodesBeforeEndpoint: { [ifConditionId: string]: string[] }, //is object has id target ifCondition node as key and value is nodes are found before the node that represents endpoint id
  eds: Edge[],
  nds: Node[],
  resIfConditionNodesConnectedTarget: {
    [ifConditionId: string]: string[];
    isBasic: any; //  indicates to the end of the parent ifCondition node
  }, // is object has id ifCondition nodes that connected with targetNode(ifCondition) as key and value is nodes are found before the node that represents endpoint id relative to the ifCondition node that connected with targetNod
  isEnableResIfConditionNodesConnectedTarget: boolean = false,
  idPointerIfConditionNode: string = "",
  isPreventPassingIfCondition: boolean = false,
  updatedNodesAsObject: { [idNode: string]: Node },
  orginalNodesAsObject: { [idNode: string]: Node },
  resLastNode: Node = {} as Node,
  nameEndPoint = "endPointId",
  resNestedNodes: { [idIfCondition: string]: string[] } = {}
) => {
  map([...outgoersNodesTargetNode], (childNode) => {
    if (
      [types.IF_CONDITION, types.HTTP_REQUEST].includes(childNode?.type || "")
    ) {
      resNestedNodes[childNode?.id] = [
        ...(resNestedNodes[childNode?.id] || []),
      ];
      let endPointId: string =
        orginalNodesAsObject?.[childNode?.id]?.data?.[nameEndPoint] || "";
      /** new lines for drawing old flows*/
      handleNestedConditon(
        childNode,
        eds,
        nds,
        resNestedNodes,
        childNode?.id,
        endPointId
      );
      handleNextIfconditionNode(
        idPointerIfConditionNode,
        updatedNodesAsObject,
        childNode,
        isPreventPassingIfCondition,
        resNodesBeforeEndpoint,
        eds,
        nds,
        resIfConditionNodesConnectedTarget,
        orginalNodesAsObject,
        nameEndPoint
      );
    } else {
      let targetEdges = filter([...eds], (e) => e?.source === childNode?.id);
      map([...targetEdges], (o) => {
        if (o?.target === targetNode?.data?.[nameEndPoint]) {
          if (isEnableResIfConditionNodesConnectedTarget) {
            resIfConditionNodesConnectedTarget[idPointerIfConditionNode] = [
              ...(resIfConditionNodesConnectedTarget[
                idPointerIfConditionNode
              ] || []),
              childNode?.id,
            ];
            if (!isEmpty(resLastNode)) {
              resIfConditionNodesConnectedTarget.isBasic = { ...resLastNode };
            }
          } else {
            resNodesBeforeEndpoint[idPointerIfConditionNode] = [
              ...(resNodesBeforeEndpoint[idPointerIfConditionNode] || []),
              childNode?.id,
            ];
          }
          return null;
        }
        const outgoersNodesChildNode: Node[] = getOutgoers(childNode, nds, eds);
        handleNestedNodesUnderIfCondition(
          targetNode,
          outgoersNodesChildNode,
          resNodesBeforeEndpoint,
          eds,
          nds,
          resIfConditionNodesConnectedTarget,
          isEnableResIfConditionNodesConnectedTarget,
          idPointerIfConditionNode,
          isPreventPassingIfCondition,
          updatedNodesAsObject,
          orginalNodesAsObject,
          resLastNode,
          nameEndPoint,
          resNestedNodes
        );
      });
    }
  });
};

export const handleNestedConditon = (
  targetNode: Node,
  eds: Edge[],
  nds: Node[],
  resNestedNodes: { [idIfCondition: string]: string[] } = {},
  basicId: string,
  endPointId: string
) => {
  let children = getOutgoers(targetNode, nds, eds);
  map(children, (item) => {
    if (endPointId === item?.id) {
      return null;
    }
    if ([types.IF_CONDITION, types.HTTP_REQUEST].includes(item?.type || "")) {
      resNestedNodes[basicId] = [
        ...(resNestedNodes?.[basicId] || []),
        item?.id,
      ];
    }
    handleNestedConditon(item, eds, nds, resNestedNodes, basicId, endPointId);
  });
};

export const handleItemKeysUserSpace = (arrKeys: Record<string, string>, targetNode: Node, title: string = "",  isArrayKey: boolean = false,) => {
  if (isEmpty(title)) {
    title = targetNode?.data?.[DataProcessingFieldName.ITEM_KEY]
  }
  let newRes: Record<string, userSpaceData> = {};
  if (
    size(arrKeys) > 0 &&
    !isEmpty(targetNode?.data?.[DataProcessingFieldName.ITEM_KEY])
  ) {
    mapKeys(arrKeys, (value: string | Object, key: string) => {
      if (key === "array" && isArray(value) && !isEmpty(value[0])) {
        let idItemLen = generateIdFlow();
        let objItemLen: userSpaceData = {
          id: idItemLen,
          title: `${title}`,
          type: value[0],
          relatedBy: targetNode?.id,
          isPrimitiveDataType: true
        };
        newRes = {
          ...newRes,
          [idItemLen]: { ...objItemLen }
        };
      } else {
        let typeVar: string = ""
        let nestedArrKeys = {}
        let idItem = generateIdFlow();
        if (typeof (value) === "object") {
          typeVar = typeVariable.ARRAY
          nestedArrKeys = { arrKeys: value };
          let idItemLen = generateIdFlow();
          let objItemLen: userSpaceData = {
            id: idItemLen,
            title: `${title}.${key}.Len`,
            type: typeVariable.NUMBER,
            relatedBy: targetNode?.id,
            isLenghtArr: true,
            [isArrayKey ? "isLengthSecondaryArr" : "isLengthSecondaryItem"]: true
          };
          newRes = {
            ...newRes,
            ...handleItemKeysUserSpace(value as Record<string, string>, targetNode, `${title}.${key}[0]`,isArrayKey),
            [idItemLen]: { ...objItemLen },
          };
        } else {
          typeVar = value
        }
        let obj: userSpaceData = {
          id: idItem,
          title: `${title}.${key}`,
          type: typeVar,
          relatedBy: targetNode?.id,
          isKey: true,
        };

        if (isArrayKey) {
          obj = {
            ...obj,
            isArrayKey,
          };
        }

        newRes = {
          ...newRes,
          [idItem]: { ...obj, ...nestedArrKeys },
        };
      }
    });
  }
  return newRes;
};


export const handleItemKeysUserSpaceFilterNode = (
  arrKeys: Record<string, string>,
  title: string = "",
  isArrayKey: boolean = false,
  targetNode: Node
) => {
  let newRes: Record<string, userSpaceData> = {};
  if (size(arrKeys) > 0 && !isEmpty(title)) {
    Object.keys(arrKeys).forEach((key: string) => {

      let value = arrKeys[key];
      if (key === "array" && isArray(value) && !isEmpty(value[0])) {
        let idItemLen = generateIdFlow();
        let objItemLen: userSpaceData = {
          id: idItemLen,
          title: `${title}`,
          type: value[0],
          relatedBy: targetNode?.id,
          isPrimitiveDataType: true
        };
        newRes = {
          ...newRes,
          [idItemLen]: { ...objItemLen }
        };
      } else {
        let typeVar: string = "";
        let nestedArrKeys: Record<string, string> = {};
        if (typeof value === "object") {
          typeVar = typeVariable.ARRAY;
          nestedArrKeys = { arrKeys: value };
          let idItemLen = generateIdFlow();
          let objItemLen: userSpaceData = {
            id: idItemLen,
            title: `${title}.${key}.Len`,
            type: typeVariable.NUMBER,
            relatedBy: targetNode?.id,
            isLenghtArr: true,
            [isArrayKey ? "isLengthSecondaryArr" : "isLengthSecondaryItem"]: true
          };
          // let idItem = generateIdFlow();
          // let objItem: userSpaceData = {
          //   id: idItem,
          //   title: `${title}.${key}[0]`,
          //   type: typeVariable.OBJECT,
          //   relatedBy: targetNode?.id,
          //   arrKeys:value,
          //   isObject:true
          // };
          newRes = {
            ...newRes,
            ...handleItemKeysUserSpaceFilterNode(value as Record<string, string>, `${title}.${key}[0]`, isArrayKey, targetNode),
            [idItemLen]: { ...objItemLen },
            // [idItem]:{...objItem}
          };
        } else {
          typeVar = value;
        }

        let idItem = generateIdFlow();
        let obj: userSpaceData = {
          id: idItem,
          title: `${title}.${key}`,
          type: typeVar,
          relatedBy: targetNode?.id,
          isKey: true,
        };

        if (isArrayKey) {
          obj = {
            ...obj,
            isArrayKey,
          };
        }

        newRes = {
          ...newRes,
          [idItem]: { ...obj, ...nestedArrKeys },
        };
      }
    });
  }
  return newRes;
};

export const handleItemKeysUserSpaceGroupByNode = (
  arrKeys: Record<string, string>,
  title: string = "",
  isArrayKey: boolean = false,
  targetNode: Node,
) => {
  let newRes: Record<string, userSpaceData> = {};
  if (size(arrKeys) > 0 && !isEmpty(title)) {
    Object.keys(arrKeys).forEach((key: string) => {
      let value = arrKeys[key];
      if (key === "array" && isArray(value) && !isEmpty(value[0])) {
        let idItemLen = generateIdFlow();
        let objItemLen: userSpaceData = {
          id: idItemLen,
          title: `${title}`,
          type: value[0],
          relatedBy: targetNode?.id,
          isPrimitiveDataType: true
        };
        newRes = {
          ...newRes,
          [idItemLen]: { ...objItemLen }
        };
      } else {
        let typeVar: string = "";
        let nestedArrKeys: Record<string, string> = {};
        if (typeof value === "object") {
          typeVar = typeVariable.ARRAY;
          nestedArrKeys = { arrKeys: value };
          let idItemLen = generateIdFlow();
          let objItemLen: userSpaceData = {
            id: idItemLen,
            title: `${title}.${key}.Len`,
            type: typeVariable.NUMBER,
            relatedBy: targetNode?.id,
            isLenghtArr: true,
            [isArrayKey ? "isLengthSecondaryArr" : "isLengthSecondaryItem"]: true
          };
          // let idItem = generateIdFlow();
          // let objItem: userSpaceData = {
          //   id: idItem,
          //   title: `${title}.${key}[0]`,
          //   type: typeVariable.OBJECT,
          //   relatedBy: targetNode?.id,
          //   arrKeys:value
          // };
          newRes = {
            ...newRes,
            ...handleItemKeysUserSpaceGroupByNode(value as Record<string, string>, `${title}.${key}[0]`, isArrayKey, targetNode),
            [idItemLen]: { ...objItemLen }
            // [idItem]:{...objItem}
          };
        } else {
          typeVar = value;
        }

        let idItem = generateIdFlow();
        let obj: userSpaceData = {
          id: idItem,
          title: `${title}.${key}`,
          type: typeVar,
          relatedBy: targetNode?.id,
          isKey: true,
        };

        if (isArrayKey) {
          obj = {
            ...obj,
            isArrayKey,
          };
        }

        newRes = {
          ...newRes,
          [idItem]: { ...obj, ...nestedArrKeys },
        };
      }
    });
  }

  return newRes;
};


// Recursive function to process nested objects
const processObject=(obj:any)=>{
  for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Check if the object has "array" key
          if ('array' in obj[key]) {
              // Replace the property value with the value of "array" key
              obj[key] = obj[key]['array'];
              // Delete the "array" key
              delete obj[key]['array'];
          } else {
              // Recursively process nested objects
              processObject(obj[key]);
          }
      }
  }
}
export const processSelectedOptionArr=(t:any,selectedOption:any)=>{
    if(isEmpty(selectedOption?.arrKeys)){
      return selectedOption?.typeArrItem|| "_"
    }
    let arrKeys =selectedOption?.arrKeys
     // Check if the input is an object
     if (typeof arrKeys === 'object' && arrKeys !== null && !Array.isArray(arrKeys)) {
      // If input is in the format { "array": ["string"] }, return the value of "array"
      if ('array' in arrKeys && Array.isArray(arrKeys['array'])) {
          return JSON.stringify(arrKeys['array']);
      }
      // Clone the input object to avoid modifying the original one
      let output = JSON.parse(JSON.stringify(arrKeys));
      // Call the recursive function with the initial object
      processObject(output);
      return JSON.stringify(output) 
  } else if (Array.isArray(arrKeys)) {
      // If input is an array, return it directly
      return JSON.stringify(arrKeys)  
  } else {
      // If input is neither an object nor an array, return null or handle as needed
      return ""
  }
}