import { createSelector } from "reselect";
import { Sessions } from "./type";

interface RootState {
  sessions: Sessions;
}

// This used to memorize the selector value
export const sessionsSelector = createSelector(
  (state: RootState) => state.sessions,
  (sessions: Sessions) => {
    return {
      activeCount: sessions.activeCount,
      activeInactiveOffsetTrack: sessions.activeInactiveOffsetTrack,
      activeOffsetTrack: sessions.activeOffsetTrack,
      activeSessionDone: sessions.activeSessionDone,
      agentFilter: sessions.agentFilter,
      assignEscalatedOffsetTrack: sessions.assignEscalatedOffsetTrack,
      assignEscalatedSessionDone: sessions.assignEscalatedSessionDone,
      chatType: sessions.chatType,
      closeOffsetTrack: sessions.closeOffsetTrack,
      closeSessionDone: sessions.closeSessionDone,
      escalatedOffsetTrack: sessions.escalatedOffsetTrack,
      escalatedSessionDone: sessions.escalatedSessionDone,
      escalatedUnassigned: sessions.escalatedUnassigned,
      escaletedAssigned: sessions.escaletedAssigned,
      globalEscalatedUnreadCount: sessions.globalEscalatedUnreadCount,
      globalUnreadCount: sessions.globalUnreadCount,
      groupFilter: sessions.groupFilter,
      inactiveCount: sessions.inactiveCount,
      inactiveOffsetTrack: sessions.inactiveOffsetTrack,
      inactiveSessionDone: sessions.inactiveSessionDone,
      loaddingSession: sessions.loaddingSession,
      loadingActiveSession: sessions.loadingActiveSession,
      loadingInactiveSession: sessions.loadingInactiveSession,
      loadingClosedSession: sessions.loadingClosedSession,
      loadingEscalatedSession: sessions.loadingEscalatedSession,
      loadingAssignedEscalatedSession: sessions.loadingAssignedEscalatedSession,
      loadingMessages: sessions.loadingMessages,
      loginStatus: sessions.loginStatus,
      messagesOffset: sessions.messagesOffset,
      msgLimitStatus: sessions.msgLimitStatus,
      openSession: sessions.openSession,
      openSessionStatus: sessions.openSessionStatus,
      openedSession: sessions.openedSession,
      receiveNewMessage: sessions.receiveNewMessage,
      searchText: sessions.searchText,
      selectedSession: sessions.selectedSession,
      selectedSessionClientInfo: sessions.selectedSessionClientInfo,
      selectedSessionData: sessions.selectedSessionData,
      sendingMessage: sessions.sendingMessage,
      sessionActiveFilter: sessions.sessionActiveFilter,
      sessionClosedFilter: sessions.sessionClosedFilter,
      sessionEscalatedFilter: sessions.sessionEscalatedFilter,
      sessionInactiveFilter: sessions.sessionInactiveFilter,
      sessionMessages: sessions.sessionMessages,
      draftMessages: sessions.draftMessages,
      sessionsActive: sessions.sessionsActive,
      sessionsClosed: sessions.sessionsClosed,
      sessionsInactive: sessions.sessionsInactive,
      sessionsUnassignEscalatedFilter: sessions.sessionsUnassignEscalatedFilter,
      transferSession: sessions.transferSession,
      joinedVideoCall: sessions.joinedVideoCall,
      videoCallParticipants: sessions.videoCallParticipants,
      loadingClientSession: sessions.loadingClientSession,
      clientSessions: sessions.clientSessions,
      clientSessionDone: sessions.clientSessionDone,
      clientOffsetTrack: sessions.clientOffsetTrack,
      reactionList: sessions.reactionList,
      loadingReactionList: sessions.loadingReactionList,
      multiSelectedSessions: sessions.multiSelectedSessions,
      currentSessionType: sessions.currentSessionType
    };
  }
);
